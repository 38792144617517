<template>
  <div>
    <div class="md:ml-72 p-3 mb-5">
      <!-- breadcrumb -->
      <nav  v-scroll-reveal class="text-sm font-semibold mb-6" aria-label="Breadcrumb">
        <ol class="list-none p-0 inline-flex">
          <li class="flex items-center text-blue-500">
            <a href="/" class="text-gray-700">Home</a>
            <svg
              class="fill-current w-3 h-3 mx-3"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
            >
              <path
                d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
              />
            </svg>
          </li>
          <li class="flex items-center">
            <a href="/admin" class="text-gray-600">Dashboard</a>
             <svg
              class="fill-current w-3 h-3 mx-3"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
            >
              <path
                d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
              />
            </svg>
          </li>
           <li class="flex items-center">
            <a  class="text-gray-600">All Students</a>
        
          </li>
        </ol>
      </nav>
      <!-- breadcrumb end -->
      <v-data-table  v-scroll-reveal
        :headers="headers"
        :items="profiles"
        sort-by="last_name"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>All Students</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>

        <template v-slot:item.first_name="{ item }">
          <a :href="'/admin/profile/' + item.id">{{ item.first_name }}</a>
        </template>
        <template v-slot:item.last_name="{ item }">
          <a :href="'/admin/profile/' + item.id">{{ item.last_name }}</a>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
//import Sidebar from "@/components/admin/Sidebar";
import DataService from "@/authentication/data.service";
export default {
  //components: { Sidebar },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    profiles: [],
    headers: [
      {
        text: "First Name",
        align: "start",
        sortable: false,
        value: "first_name",
      },
      { text: "Last Name", value: "last_name" },
      { text: "Contact", value: "contact_number" },
      { text: "Email", value: "email" },
      { text: "Gender", value: "gender" },
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },
  async mounted() {
    await DataService.getAllUserProfiles().then(
      (response) => {
        const myresult = response.data.filter(
          (profile) => profile.user_type == "Student"
        );
        //this.profiles = result;
        for (const result of myresult) {
          const data = {
            first_name: result.user.first_name,
            last_name: result.user.last_name,
            email: result.user.email,
            contact_number: result.contact_number,
            gender: result.gender,
            id: result.user.id,
          };
          this.profiles.push(data);
          
        }
      },
      (error) => {
        this.content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
    
      }
    );
  },

  methods: {
    initialize() {
      this.profiles = [];
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.profiles[this.editedIndex], this.editedItem);
      } else {
        this.profiles.push(this.editedItem);
        alert("new data added successifuly");
      }
      this.close();
    },
  },
};
</script>